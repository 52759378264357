<style lang="scss"></style>
<!--eslint-disable-->
<template>
  <div>
    <b-card title="Submit an issue">
      <b-card-text>This page allows you to submit any issues you have found on the MDM portal or with one of our apps</b-card-text>
    </b-card>

    <b-card class="mb-0" title="Submit Issue Form">
      <p>Please use the form below to describe your issue.</p>
      <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(submitIssue)">
            <section class="mb-2">
              <div>
                <label>Subject</label>
                <validation-provider
                  v-slot="validationContext"
                  name="Subject"
                  :rules="{ required: true, max: 50 }"
                >
                  <b-form-input
                    v-model="issue.subject"
                    :state="getValidationState(validationContext)" class="mb-1"
                    placeholder="e.g Crossword application keeps crashing"
                  />

                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div>
                <label>Severity</label>
                <validation-provider
                  v-slot="validationContext"
                  name="Group"
                  :rules="{ required: true }"
                >
                  <b-form-select v-model="issue.severity" class="mb-2" :state="getValidationState(validationContext)">
                    <b-form-select-option value="" disabled>Choose a severity level</b-form-select-option>
                    <b-form-select-option v-for="option in severities" :key="option.value" :value="option.value"
                                          class="text-capitalize"
                    >
                      {{ option.label }}
                    </b-form-select-option>
                  </b-form-select>
                </validation-provider>
              </div>

              <div>
                <label>Please provide a full description of the error you are having</label>
                <validation-provider
                  v-slot="validationContext"
                  name="Description"
                  :rules="{ required: true, max: 5000 }"
                >
                  <b-form-textarea
                    v-model="issue.description"
                    :state="getValidationState(validationContext)" class="mb-1"
                    placeholder="Enter a longer, and full description of the issue"
                  />

                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div>
                <label>Are there any error messages or warnings shown?</label>
                <validation-provider
                  v-slot="validationContext"
                  name="Erros"
                  :rules="{ required: false, max: 5000 }"
                >
                  <b-form-textarea
                    v-model="issue.errors"
                    :state="getValidationState(validationContext)" class="mb-1"
                    placeholder="Enter a list of error messages shown on the page"
                  />

                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

              <div>
                <label>Please explain any steps needed for us to be able to reproduce the error</label>
                <validation-provider
                  v-slot="validationContext"
                  name="Erros"
                  :rules="{ required: false, max: 5000 }"
                >
                  <b-form-textarea
                    v-model="issue.reproduction"
                    :state="getValidationState(validationContext)" class="mb-1"
                    placeholder="Enter some instructions on how to reproduce the issue"
                  />

                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
            </section>

            <section class="d-inline-flex full-width mt-2">
              <div class="ui-spacer" />
              <b-button variant="primary" type="submit">Submit issue</b-button>
            </section>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
 import IssuesService from '@/services/IssuesService'

  export default {
    name: 'SubmitIssue',
    components: {},
    props: {},
    data() {
      return {
        severities: [
          { value: 'CRITICAL', label: 'Critical: Service Unusable in Production. All users effected' },
          { value: 'HIGH', label: 'High: Service Use Severely Impaired. No easy workaround available.' },
          { value: 'MEDIUM', label: 'Medium: Service Use Partially Impaired. The issue is limited in scope and/or severity' },
          { value: 'LOW', label: 'Low: Service Fully Usable or minor issue detected. Require consultation or advise.' },
        ],
        issue: {
          subject: '',
          severity: '',
          description: '',
          errors: '',
          reproduction: '',
        },
      }
    },
    mounted() {},
    methods: {
      submitIssue() {
        IssuesService.submitIssue(this.issue)
          .then(res => {
            this.$toast.success('Issue submitted successfully', {
              toastClassName: ['toast-std', 'success-toast'],
            })

            this.$router.push({ name: 'issue-submitted', params: { uid: res.data.id.toString() } });
          })
          .catch(err => {
            const res = err.response
            let errorText = 'Could not submit issue, please refresh and try again'

            if (res && res.data.error) {
              errorText = res.data.error
            }

            this.$toast.error(errorText, {
              toastClassName: ['toast-std', 'warning-toast'],
            })
          })
      },
      getValidationState({
        dirty,
        validated,
        valid = null,
      }) {
        return dirty || validated ? valid : null
      },
    },
  };
</script>
