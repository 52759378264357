import axios from '@axios';

const baseUrl = '/issues';

export default {
  submitIssue(issues) {
    return axios.post(`${baseUrl}`, issues);
  },
  getIssue(id) {
    return axios.get(`${baseUrl}/${id}`)
  },
};
